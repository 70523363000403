@use '../variables' as var;
@use '@material/button' as mdc-button;

@mixin material-button-common() {
  @include mdc-button.container-fill-color(var.$color-dmvBlue);
  @include mdc-button.ink-color(var.$color-white);

  // Hover state
  &:hover {
    @include mdc-button.container-fill-color(darken(var.$color-dmvBlue, 5%));
  }

  // Focus state
  &:focus {
    @include mdc-button.container-fill-color(lighten(var.$color-dmvBlue, 5%));
    border: 2px solid var.$color-darkGray;
    opacity: 1;
  }

  // Active or pressed state
  &:active {
    @include mdc-button.container-fill-color(darken(var.$color-dmvBlue, 10%));
  }

  height: 48px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border-radius: 6px;
  padding: 0.5rem 1rem;
}

@mixin material-button-toggle-common() {
  @include mdc-button.container-fill-color(var.$color-white);
  @include mdc-button.ink-color(var.$color-darkGray);

  // Hover state
  &:hover {
    @include mdc-button.container-fill-color(darken(var.$color-white, 5%));
  }

  // Focus state
  &:focus {
    border: 2px solid var.$color-darkGray;
    opacity: 1;
  }

  // Active or pressed state
  &:active {
    @include mdc-button.container-fill-color(darken(var.$color-white, 10%));
  }

  &.mat-button-toggle-checked {
    @include mdc-button.container-fill-color(var.$color-lightGray);
  }

  height: 48px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border-radius: 6px;
  padding: 0.5rem 1rem;
}

@mixin material-basic-button-common($height: 48px) {
  @include mdc-button.container-fill-color(var.$color-white);
  @include mdc-button.ink-color(var.$color-dmvBlue);

  // Hover state
  &:hover {
    @include mdc-button.container-fill-color(var.$color-lightGray);
  }

  // Focus state
  &:focus {
    @include mdc-button.container-fill-color(var.$color-lightGray);
    opacity: 1;
  }

  // Active or pressed state
  &:active {
    @include mdc-button.container-fill-color(var.$color-dmvBlue);
    @include mdc-button.ink-color(var.$color-white);
  }

  // Material by default sets a z index on the button label and that causes
  // text to be rendered through "solid" content such as our MVR footer
  & .mdc-button__label {
    z-index: unset;
  }

  border: 2px solid var.$color-dmvBlue;
  height: $height;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border-radius: 6px;
  padding: 0.5rem 1rem;
}

@mixin material-button-overlay-common() {
  background-color: transparent;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
}

@mixin material-button() {
  // Target buttons not within the Material Datepicker
  .mat-mdc-button:not(.mat-datepicker-content .mat-mdc-button),
  .mat-mdc-unelevated-button:not(.mat-datepicker-content .mat-mdc-unelevated-button),
  .mat-mdc-raised-button:not(.mat-datepicker-content .mat-mdc-raised-button),
  .mat-mdc-outlined-button:not(.mat-datepicker-content .mat-mdc-outlined-button) {
    @include material-button-common();
  }

  ::ng-deep .mat-mdc-icon-button.cdk-keyboard-focused {
    border: 2px solid var.$color-darkGray;
    opacity: 1;
  }

  ::ng-deep {
    .mat-mdc-unelevated-button.cdk-keyboard-focused &.focus,
    .mat-mdc-raised-button.cdk-keyboard-focused &.focus {
      border: 1px solid var.$color-white;
    }
  }

  ::ng-deep {
    .mat-mdc-outlined-button .mat-mdc-button-focus-overlay,
    .mat-stroked-button .mat-button-ripple.mat-ripple,
    .mat-button .mat-button-focus-overlay,
    .mat-button-focus-overlay {
      @include material-button-overlay-common();
    }
  }
}

@mixin material-basic-button($height: 48px) {
  .mat-mdc-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button,
  .mat-mdc-outlined-button {
    @include material-basic-button-common($height);
  }
}

@mixin material-toggle-button() {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-group-appearance-standard {
    border-radius: 6px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  ::ng-deep .mat-button-toggle {
    @include material-button-toggle-common();
    height: 100%;
    border-radius: unset;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  ::ng-deep .mat-button-toggle-focus-overlay {
    @include material-button-overlay-common();
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  ::ng-deep .mat-button-toggle-appearance-standard.cdk-keyboard-focused:not(.mat-button-toggle-disabled) .mat-button-toggle-focus-overlay {
    border: 2px solid var.$color-darkGray;
    opacity: 1;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  ::ng-deep .mat-button-toggle-group-appearance-standard {
    border: none;
  }
}
