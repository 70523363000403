/**************************************************************
**  Form Styles
**************************************************************/

@use '@dmv/theme/partials/variables' as var;

/* Radio button customizations */
.form-check {
  border: 1px solid #d6e5eb;
}
.form-check-input {
  margin-left: -1.23rem;
}

.form-section {
  margin-bottom: 40px;
  position: relative;
}

h2.form-section-title {
  font-size: 16px;
  color: var.$color-dmvBlue;
  border-bottom: 2px solid var.$color-dmvBlue;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.same-address-check {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}
.form-check label {
  width: 100%;
  cursor: pointer;
}
