@use '../mixins';

.show-on-mobile {
  display: none;
}

.text-left {
  text-align: left;
}

.open-in-new-icon.material-icons {
  @include mixins.matIcon-adjust-mat-icon-size(16px);
  vertical-align: top;
}

@media (max-width: 576px) {
  .hide-on-mobile {
    display: none;
  }

  .show-on-mobile {
    display: block;
  }
}
