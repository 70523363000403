/**************************************************************
**  Header Styles
**************************************************************/

@use '@dmv/theme/partials/variables' as var;
@import 'bootstrap/scss/mixins';

#header {
  background-color: var.$color-dmvBlue;
  border-bottom: 5px solid var.$color-dmvLightBlue;
  min-height: 95px;
  padding: 18px 0;

  .navbar {
    padding: 0;

    a {
      color: var.$color-white;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

/*---------------------------------------------------------
	-- Media Queries
	---------------------------------------------------------*/

// small and up
@include media-breakpoint-up(sm) {
}

// medium and up
@include media-breakpoint-up(md) {
}

// large and up
@include media-breakpoint-up(lg) {
}

// x-large and up
@include media-breakpoint-up(xl) {
}
