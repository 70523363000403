/**************************************************************
**  Upload Panel Styles
**************************************************************/

@use '@dmv/theme/partials/variables' as var;

.upload-accordion-wrapper {
  .card {
    .card-header {
      background-color: #fafafa;
      border: 1px solid #eee;
      margin-bottom: 0 !important;
      width: 100%;

      button {
        &.btn-link {
          color: var.$color-lightBlue;
          text-decoration: none;
        }

        .upload-item {
          font-size: 14px;
          display: inline-block;
          text-align: left;
          margin-right: 110px;
          position: absolute;
          width: 100%;
        }

        .upload-item-info {
          display: flex;
          width: 75%;
        }

        .upload-item-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .card-body {
      padding: 30px;
      background-color: #f5f5f5;
    }
  }

  i {
    color: #000000;
    margin-right: 10px;
  }

  .preview-image {
    padding: 10px;
    background: #fff;
    border: 1px solid #eee;
    margin-bottom: 5px;

    img {
      width: 100%;
    }
  }

  .docai-image {
    padding: 20px;
    background: #d4d4d4;
    border: 1px solid #eee;
    margin-bottom: 5px;
    height: 200px;

    align-items: center;
    justify-content: center;
    display: flex;

    img {
      height: 164px;
    }
  }

  .upload-title {
    font-size: 17.5px;
    font-weight: 500;
  }

  .complete-icon {
    color: #22b8cf;
    font-size: 22px;
    position: absolute;
    right: 0;
    top: 18px;
  }

  .previewIcon {
    position: absolute;
    right: 80px;
    top: -6px;
  }
}

.full-size {
  .modal-dialog {
    height: 100%;
  }

  lib-pdf-viewer {
    height: 100%;
  }
}
