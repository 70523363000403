@use '../functions/rem';
@use '../variables' as var;

@mixin formFieldStyle() {
  .mat-mdc-form-field {
    padding-right: 1rem;
    padding-left: 0;
    padding-bottom: 0.5rem;
  }

  .mat-mdc-form-field-subscript-wrapper {
    margin-bottom: 0.5rem;
  }

  .mat-mdc-form-field.mat-focused .mat-form-field-label {
    color: var.$color-dmvBlue;
    padding-top: 0.5rem;
  }

  .mat-mdc-input-element {
    caret-color: var.$color-dmvBlue;
  }

  .mat-radio-label {
    align-items: start;
  }

  .mat-mdc-form-field.mat-focused .mat-mdc-select .mat-select-arrow {
    color: var.$color-dmvBlue;
  }

  .mat-icon {
    height: unset;
  }

  @media only screen and (max-width: 576px) {
    .mat-mdc-form-field-error {
      width: max-content;
    }
  }
}
