@use '../variables' as var;

@mixin toast-error-content-list() {
  ::ng-deep .toast-content-list {
    padding-inline-start: 0;
    margin-bottom: 0;

    ::ng-deep .toast-container {
      margin-bottom: 1rem;
    }

    .bold {
      font-family: var.$font-fontBold;
    }

    &.toast-content-list-error {
      padding-inline-start: 2rem; // width of error icon in toast
      margin-bottom: 0;
    }
  }
}
