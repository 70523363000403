/**************************************************************
**  Transaction Stepper Styles
**************************************************************/

@use '@dmv/theme/partials/variables' as var;

.step-progress .col {
  background: #fafafa;
  border-top: 7px solid var.$color-darkGray;
  padding: 5px 0 5px;
  color: #63666a;
}
.step-progress .col.active {
  background: #ffffff;
  border-top: 7px solid #3c763d;
  font-family: var.$font-fontBold;
  color: var.$color-black;
}
.step-progress .col.completed {
  background: #ffffff;
  border-top: 7px solid #3c763d;
  color: #3c763d;
}
.step-progress .col.completed:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
  content: '\f058';
  position: absolute;
  top: 15px;
  left: 0;
}

@media (max-width: 768px) {
  .border-right {
    border-right: 0 !important;
  }
  .step-progress .col.completed:before {
    top: 0;
  }
}
/* Place the completed icon upper left */
@media (max-width: 991px) {
  .step-progress .col.completed:before {
    top: 5px;
  }
}
