/**************************************************************
**  Required Document Styles
**************************************************************/

@use '@dmv/theme/partials/variables' as var;

#required-documents {
  .required-document {
    background: #f5f5f5;
    padding: 10px;
    border-left: 5px solid var.$color-lightBlue;
    margin-bottom: 8px;

    a,
    i {
      color: var.$color-anchorColor;
    }
  }
}

.document-list-options {
  label.checkbox-label {
    margin-bottom: 0;

    input {
      position: relative;
      top: 2px;
      left: -3px;
    }
  }
}
