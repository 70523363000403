/**************************************************************
**  Additional Info Requested
**************************************************************/

@use '@dmv/theme/partials/variables' as var;

#additional-info-requested {
  .grey-container {
    background: #fafafa;
    -webkit-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
    padding: 3rem;

    .secure,
    .secure i {
      color: #3c763d;
    }
    .btn-outline-primary {
      background-color: transparent;
      color: #0077c8;
      border-color: #0077c8;
      border-radius: 0;
      font-family: var.$font-fontRegular;
      font-size: 1.1rem;
      padding: 10px 10px;
    }
    .review-section {
      background: #eaeaea;
      padding: 20px 0 20px;
    }
    .review-section p {
      margin-bottom: 0;
    }
    .icon-container {
      background: #ffffff;
      box-shadow: inset -37px 0px 0 0 #0077c8;
    }
    .icon-container .btn:focus,
    .icon-container .btn:active {
      outline: none !important;
      box-shadow: none;
    }
    .icon-container i {
      color: #ffffff;
    }
    // Large devices (desktops, 992px and up)
    @media (max-width: 992px) {
      .grey-container {
        background: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
      }
    }
    /**************************************************************
        **  Message Styles
        **************************************************************/
    .message-history {
      background: var.$color-white;
      overflow-y: scroll;
      padding: 10px;
      border: 1px solid #bdbdbd;
      height: 50%;
      .message {
        margin-bottom: 30px;
        .message-timestamp {
          font-size: 11px;
          font-weight: bold;
          color: var.$color-darkGray;
          margin-bottom: 10px;
        }
        .message-content {
          box-shadow: 1px 1px 2px rgba(55, 55, 55, 0.25);
          border-radius: 8px;
          position: relative;
          padding: 10px;
          border: 1px solid #f5f5f5;
        }
        .message-avatar {
          position: absolute;
          top: 0;
        }
      }
      .mvr-message {
        .message-timestamp {
          text-align: right;
        }
        .message-content {
          margin-left: 50px;
        }
        .message-avatar {
          width: 32px;
          height: 32px;
          background: var.$color-dmvBlue;
          border-radius: 16px;
          text-align: center;
          padding-top: 6px;
          color: var.$color-white;
          left: -45px;
        }
      }
      .message-older {
        font-size: 11px;
        font-weight: normal;
        margin-bottom: 20px;
        text-align: center;
      }
    }
  }
}
