/**************************************************************
**  Main Styles
**************************************************************/

@use '@dmv/theme/partials/variables' as var;

#main {
  padding: 15px 0;
}

.footer-container {
  margin: 80px 0;
  width: 100%;
}

i.large-icon-success {
  font-size: 70px;
  color: var.$color-green;
}
