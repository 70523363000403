/************************************************************************
IMPORTANT: DON'T EXPORT ANYTHING THAT PRODUCES CSS CODE!
************************************************************************/

@forward './partials/mixins' as mixin-*;
@forward './partials/variables' as var-*;
@forward './partials/functions' as function-*;
@forward './partials/utilities' as util-*;

@use './fonts.css';
@use './partials/functions/rem';
@use './themes/material-theme';
@use './partials/variables' as var;

@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp|Material+Symbols+Outlined');

html,
body {
  font-family: var(--theme-typography-font-family);
  font-size: var(--theme-typography-font-size-normal);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--theme-typography-font-weight-bold);
}

a {
  text-decoration: underline;
  color: var(--theme-color-link);
}

.font-weight-bold {
  font-weight: var(--theme-typography-font-weight-bold);
}

label {
  font-weight: var(--theme-typography-font-weight-normal);
}

i.large-icon-warning {
  font-size: 70px;
  color: var(--theme-color-warning);
}
.warning {
  color: var(--theme-color-warning);
}
.secure,
.secure i {
  color: var(--theme-color-success);
}
.text-primary {
  color: var(--theme-color-primary);
}
.skip-link:not(:focus) {
  opacity: 0;
  display: inline;
}
