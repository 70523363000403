/**************************************************************
**  Public Styles - Temp Placeholder
**************************************************************/

@use '@dmv/theme/partials/variables' as var;

#document-upload-wrapper {
  margin-top: 30px;

  .card {
    margin-bottom: 10px;
  }

  .card-header .btn-link {
    color: var.$color-lightBlue;
    font-family: var.$font-fontBold;
  }
}

/**************************************************************
**  Personal Information
**************************************************************/

.licence_disabled {
  opacity: 0.3;
}

.delivery-sec {
  .form-check-label span {
    display: block;
  }
}

/**************************************************************
**  Upload Documents
**************************************************************/

.card,
.card-header {
  border-radius: 0;
  border: 0;
}
.accordion > .card > .card-header {
  margin-bottom: 10px;
}

/**************************************************************
**  Review
**************************************************************/

.review-section {
  background: var.$color-lightGray;
  padding: 20px 0 20px;
}
.review-section p {
  margin-bottom: 0;
}

/**************************************************************
**  Proof of Name
**************************************************************/

.name-points-badge {
  font-size: 14px;
}

/**************************************************************
**  Confimation - OP
**************************************************************/

.border-left-4 {
  border-left: 4px solid var.$color-mediumGray;
}
ul.grey-list {
  padding: 0 0 0 10px;
}
ul.grey-list li {
  background: #f6f6f6;
  list-style-position: inside;
  padding: 5px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}
