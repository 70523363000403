/**************************************************************
**  User Feedback Message Styles
**************************************************************/

@use '@dmv/theme/partials/variables' as var;

.message-wrapper {
  background: #fcf9e3;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  font-family: var.$font-fontSemiBold;
  border: 2px solid #896e3b;
  margin-bottom: 50px;

  .message {
    padding-bottom: 10px;
  }
}

/**************************************************************
**  Confirmation Message Styles
**************************************************************/

.confirmation-wrapper {
  background: #f8fafa;
  border-radius: 8px;
  padding: 20px;
  font-family: var.$font-fontRegular;
  border: 2px solid var.$color-green;
  font-size: 16px;
}

/**************************************************************
**  Selection Details Wrapper
**************************************************************/

.selection-details-wrapper {
  background: #f6f6f6;
  padding: 15px;
  font-family: var.$font-fontRegular;
  font-size: 16px;
}

/**************************************************************
**  Highlight Wrapper
**************************************************************/

.highlight-wrapper {
  border-radius: 8px;
  padding: 20px;
  font-family: var.$font-fontSemiBold;
  border: 2px solid var.$color-dmvLightBlue;
  font-size: 16px;
  margin-bottom: 1rem;
  text-align: center;
}

.confirmation-wrapper-black {
  background: #f8fafa;
  border-radius: 8px;
  padding: 20px;
  font-family: var.$font-fontRegular;
  border: 2px solid black;
  font-size: 16px;
}
