// import lib styles
@use '@dmv/theme';
@use '@dmv/theme/partials/variables' as var;
@use '@dmv/theme/partials/mixins' as mixins;
@include mixins.checkbox-styleCheckbox();
@include mixins.matButton-material-button();
@include mixins.toastError-toast-error-content-list;
@include mixins.matFormField-formFieldStyle;

@import 'bootstrap/scss/bootstrap.scss';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/regular';

// import component styles
@import 'components/_badge';
@import 'components/_buttons';
@import 'components/_form';
@import 'components/_message';
@import 'components/_progress-bar';
@import 'components/_required-document';
@import 'components/_transaction-stepper';
@import 'components/_upload-panel';
// import layout styles
@import 'layout/_additional-info-requested';
@import 'layout/_header';
@import 'layout/_main';
@import 'layout/_public';

.form-section-title {
  font-size: 16px;
  color: var.$color-dmvBlue;
  border-bottom: 2px solid var.$color-dmvBlue;
  margin: 20px 0;
  padding-bottom: 5px;
  font-family: var.$font-fontBold;
}
