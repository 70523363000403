@use '@dmv/theme/partials/variables' as var;

/**************************************************************
**  Button Styles
**************************************************************/

.btn-primary {
  background-color: var.$color-dmvBlue;
  border-color: var.$color-dmvBlue;
  border-radius: 0;
  min-width: 250px;
  font-family: var.$font-fontBold;
  font-size: 1.1rem;
  letter-spacing: 1px;
  padding: 10px 0 10px;
}
.btn-primary:hover {
  background-color: #0a466c;
  border-color: #0a466c;
}
.btn-secondary {
  background-color: var.$color-white;
  border-color: var.$color-dmvBlue;
  border-radius: 0;
  min-width: 250px;
  font-family: var.$font-fontBold;
  font-size: 1.1rem;
  letter-spacing: 1px;
  padding: 10px 0 10px;
  color: var.$color-black;
}
.btn-secondary:hover {
  background-color: var.$color-lightGray;
  border-color: var.$color-lightGray;
  color: var.$color-black;
}
.btn-link {
  color: var.$color-anchorColor;
  text-decoration: underline;
}
.btn-link:hover {
  color: var.$color-anchorHoverColor;
  text-decoration: underline;
}
.btn-outline-primary {
  background-color: transparent;
  color: var.$color-anchorColor;
  border-color: var.$color-anchorColor;
  border-radius: 0px;
  font-family: var.$font-fontRegular;
  font-size: 1.1rem;
  padding: 10px 0 10px;
}
.btn-outline-primary:hover {
  background-color: transparent;
  color: var.$color-anchorHoverColor;
  border-color: var.$color-anchorHoverColor;
  border-radius: 0px;
  font-family: var.$font-fontRegular;
  font-size: 1.1rem;
  padding: 10px 0 10px;
}
.btn-outline-primary i {
  color: var.$color-anchorColor;
}
.btn-group .btn-primary {
  display: flex;
  background-color: var.$color-dmvBlue;
  color: #ffffff;
  font-family: var.$font-fontRegular;
  font-size: 1.5rem;
  letter-spacing: 1px;
  align-items: center;
  justify-content: center;
  padding: 3rem 0.5em;
  border: 2px solid var.$color-dmvBlue;
  width: 235px;
  margin: 0 20px 20px 20px;
  text-align: center;
}
.btn-group .btn-primary.active {
  background-color: #ffffff !important;
  color: var.$color-dmvBlue !important;
  border: 2px solid var.$color-dmvBlue !important;

  &.focus,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn-disabled {
  background-color: var.$color-darkGray;
  border-radius: 0;
  min-width: 250px;
  font-family: var.$font-fontBold;
  font-size: 1.1rem;
  letter-spacing: 1px;
  padding: 10px 0 10px;
}
