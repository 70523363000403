/**************************************************************
**  Progress Bar Styles
**************************************************************/

@use '@dmv/theme/partials/variables' as var;

.progress {
  display: flex;
  overflow: hidden;
  line-height: 0;
  font-size: 1rem;
  background-color: #fafafa;
  border-radius: 2.25rem;
  height: 60px;
  border: 1px solid #607f61;
  font-weight: 600;
  color: #000;
  position: relative;
  margin: 40px 0;
}
.progress p {
  margin: 0;
  top: 30px;
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  color: var.$color-black;
}
.progress-bar {
  background-color: #d0e9c6;
}
